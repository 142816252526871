import NotFound from '@/components/common/NotFound';

const PageNotFound = () => {
  return <NotFound />;
};

PageNotFound.layout = function Layout({ children }: { children: any }) {
  return children;
};

export default PageNotFound;
